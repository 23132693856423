import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  APPROVAL_STATUS,
  EXTERNAL_KYC_LINK,
  KYC_PROVIDERS,
  ONBOARDING_STATUS,
  PASS_OR_FAIL,
  PERSON_TITLE,
  RISK_PROFILE,
  YES_OR_NO
} from 'components/constants';
import countries from 'components/countries';
import i18nContext from 'components/i18n-context';
import OperatorsAsyncSelect from 'components/OperatorsAsyncSelect';
import { useApplicationContext } from 'contexts/ApplicationContext';
import {
  ACCOUNT_STATUS_UPDATE,
  CORPORATE_ACCOUNT_DETAILS_UPDATE,
  hasRole,
  INDIVIDUAL_ACCOUNT_DETAILS_UPDATE
} from 'services/roles';
import {
  convertDate,
  formatDateTime,
  countryCodeRegExp,
  emailRegExp,
  parseExternalKycState,
  regularFieldRegExp
} from 'services/utils';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './CustomerDetailsTab.scss';

const CustomerDetailsTab = ({
  customerDetails,
  fieldsErrors,
  userData,
  handleUpdateCustomerDetails,
  onChangeCustomerStatus,
  onChangeOnboardingStatus,
  onSaveEmail,
  onSaveKycStatus,
  onChangeExternalKycReference,
  onSaveRiskProfile,
  onSaveMlroApproval,
  onSaveDirectorApproval,
  onSaveNextAMLCheckDate,
  onReceiveKycData
}) => {
  const i18n = useContext(i18nContext);
  const { constants } = useApplicationContext();

  const customerStatusOptions = constants?.accountStatuses.map((value) => ({
    key: value,
    value: i18n.getMessage(`customers.status.${value}`)
  }));

  const onboardingStatusOptions = Object.keys(ONBOARDING_STATUS).map((status) => {
    return {
      key: ONBOARDING_STATUS[status],
      value: i18n.getMessage('onboardingStatus.' + ONBOARDING_STATUS[status])
    };
  });

  const titleOptions = Object.values(PERSON_TITLE).map((value) => ({
    key: value,
    value: i18n.getMessage(`personTitle.${value}`)
  }));

  const countriesOptions = Object.values(countries).map((value) => ({
    key: value,
    value: i18n.getMessage(value)
  }));

  const kycStatusOptions = constants?.kycStatuses.map((value) => ({
    key: value,
    value: i18n.getMessage(`kyc.status.${value}`)
  }));

  const riskProfileOptions = Object.values(RISK_PROFILE).map((value) => ({
    key: value,
    value: i18n.getMessage(`customers.riskProfile.${value}`)
  }));

  const yesOrNoOptions = Object.values(YES_OR_NO).map((value) => ({
    key: value,
    value: i18n.getMessage(`customers.yesOrNoSelect.${value}`)
  }));

  const passOrFailOptions = Object.values(PASS_OR_FAIL).map((value) => ({
    key: value,
    value: i18n.getMessage(`customers.passOrFailSelect.${value}`)
  }));

  const approvalOptions = Object.values(APPROVAL_STATUS).map((value) => ({
    key: value,
    value: i18n.getMessage(`customers.approvalStatus.${value}`)
  }));

  const requiredFieldErrorMessage = i18n.getMessage('customer.validation.isRequired');
  const invalidFormatErrorMessage = i18n.getMessage('customer.validation.invalidFormat');
  const invalidCountryCodeErrorMessage = i18n.getMessage('customer.validation.invalidCountryCode');

  const validationSchema = Yup.object().shape({
    source: Yup.string()
      .nullable()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(250, i18n.getMessage('customer.validation.maxSize', { size: 250 })),
    name: Yup.string()
      .required(requiredFieldErrorMessage)
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 })),
    surname: Yup.string()
      .required(requiredFieldErrorMessage)
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 })),
    birthday: Yup.date(),
    passportNumber: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(30, i18n.getMessage('customer.validation.maxSize', { size: 30 })),
    passportCountry: Yup.string().length(2).matches(countryCodeRegExp, invalidCountryCodeErrorMessage).uppercase(),
    passportDate: Yup.date().nullable(),
    passportExpiryDate: Yup.date().nullable().required(requiredFieldErrorMessage),
    address: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 })),
    zipCode: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(10, i18n.getMessage('customer.validation.maxSize', { size: 10 })),
    city: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 })),
    country: Yup.string().length(2).matches(countryCodeRegExp, invalidCountryCodeErrorMessage).uppercase(),
    position: Yup.string()
      .required(requiredFieldErrorMessage)
      .max(255, i18n.getMessage('customer.validation.maxSize', { size: 255 })),
    accountOpeningPurpose: Yup.string()
      .required(requiredFieldErrorMessage)
      .max(255, i18n.getMessage('customer.validation.maxSize', { size: 255 })),
    pepList: Yup.string().required(requiredFieldErrorMessage).oneOf(['PASS', 'FAIL']),
    nextAMLCheckDate: Yup.date().nullable(),
    customerOwner: Yup.object().required(requiredFieldErrorMessage),
    agentName: Yup.string()
      .notRequired()
      .max(255, i18n.getMessage('customer.validation.maxSize', { size: 255 })),
    contactPhone: Yup.string()
      .notRequired()
      .max(25, i18n.getMessage('customer.validation.maxSize', { size: 25 })),
    contactEmail: Yup.string()
      .notRequired()
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 }))
      .trim()
      .matches(emailRegExp, { message: i18n.getMessage('customer.validation.invalidEmail') }),
    googleDriveLink: Yup.string()
      .notRequired()
      .max(255, i18n.getMessage('customer.validation.maxSize', { size: 255 })),
    internalAmlDocumentsLink: Yup.string()
      .notRequired()
      .max(255, i18n.getMessage('customer.validation.maxSize', { size: 255 }))
    /* Hidden within the task DEV-2750 as per the request of Alex Reshko. */
    // additionalComments: Yup.string(),
    // commentToDocuments: Yup.string(),
    // passportCheck: Yup.string(),
    // sanctionList: Yup.string(),
    // pepList: Yup.string(),
    // blackList: Yup.string(),
    /* Hidden within the task DEV-2750 as per the request of Alex Reshko. */
  });

  const form = useFormik({
    enableReinitialize: false,
    validateOnChange: false,
    initialValues: {
      userData: userData,
      email: customerDetails.email,
      source: customerDetails.source ? customerDetails.source : '',
      name: customerDetails.name,
      surname: customerDetails.surname,
      birthday: customerDetails?.details?.birthday || '',
      passportNumber: customerDetails?.details?.passport_number,
      passportCountry: customerDetails?.details?.passport_issue_country,
      passportDate: customerDetails?.details?.passport_issue_date,
      passportExpiryDate: customerDetails?.details?.passport_expiry_date,
      address1: customerDetails?.details?.address.address_1,
      address2: customerDetails?.details?.address.address_2,
      city: customerDetails?.details?.address.city,
      country: customerDetails?.details?.address.country,
      zipCode: customerDetails?.details?.address.zip_code,
      sanctionsRelated: typeof userData.sanctionsRelated === 'boolean' ? userData.sanctionsRelated : '',
      adverseMediaInvolved: typeof userData.adverseMediaInvolved === 'boolean' ? userData.adverseMediaInvolved : '',
      position: userData.position || '',
      title: userData.title || '',
      accountOpeningPurpose: userData.accountOpeningPurpose || '',
      pepList: userData.pepList || '',
      nextAMLCheckDate: userData.nextAMLCheckDate,
      customerOwner: customerDetails?.customer_owner || '',
      agentName: customerDetails?.agent_name || '',
      customerStatus: customerDetails?.status,
      kycStatus: userData?.kycStatus,
      kycExternalReference: customerDetails?.kycExternalReference || '',
      riskProfile: customerDetails?.risk_profile || '',
      mlroApproval: customerDetails?.mlro_approval || '',
      directorApproval: customerDetails?.director_approval || '',
      onboardingStatus: customerDetails.onboardingStatus || '',
      contactPhone: customerDetails.contactPhone || customerDetails.user.phone || '',
      contactEmail: customerDetails.contactEmail || customerDetails.email || '',
      googleDriveLink: customerDetails.googleDriveLink || '',
      internalAmlDocumentsLink: customerDetails.internalAmlDocumentsLink || ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleUpdateCustomerDetails({
        details: {
          address: {
            address_1: values.address1 ? values.address1.trim() : '',
            address_2: values.address2 ? values.address2.trim() : '',
            city: values.city ? values.city.trim() : '',
            country: values.country ? values.country : '',
            zip_code: values.zipCode ? values.zipCode.trim() : ''
          },
          birthday: (values.birthday ? values.birthday.trim() : '') || null,
          passport_number: values.passportNumber ? values.passportNumber.trim() : '',
          passport_issue_country: values.passportCountry,
          passport_issue_date: (values.passportDate ? values.passportDate.trim() : '') || null,
          passport_expiry_date: (values.passportExpiryDate ? values.passportExpiryDate.trim() : '') || null,
          position: values.position,
          title: values.title || null,
          account_opening_purpose: values.accountOpeningPurpose
        },
        hashtags:
          customerDetails.hashtags && typeof customerDetails.hashtags === 'string'
            ? customerDetails.hashtags.split('\n')
            : [],
        name: values.name ? values.name : '',
        surname: values.surname ? values.surname : '',
        declaredAccountTurnover: customerDetails.declaredAccountTurnover,
        partners: customerDetails.partners,
        additionalComments: values.additionalComments,
        commentToDocuments: values.commentDocuments,
        passportCheck: values.passportCheck || 'PASS',
        sanctionList: values.sanctionList || 'PASS',
        pepList: values.pepList || 'PASS',
        blackList: values.blacklist || 'PASS',
        source: values.source,
        sanctionsRelated: values.sanctionsRelated,
        adverseMediaInvolved: values.adverseMediaInvolved,
        customerOwner: values.customerOwner ? values.customerOwner.id : null,
        agentName: values.agentName,
        contactEmail: values.contactEmail,
        contactPhone: values.contactPhone
      });
    }
  });

  const { values, errors, handleSubmit, handleChange, setFieldValue, validateForm, resetForm } = form;
  const firstCreatedAccount =
    customerDetails?.wallets && customerDetails?.wallets.length
      ? customerDetails?.wallets.sort((a, b) => a.created_at - b.created_at)[0]
      : undefined;
  const externalKycState = parseExternalKycState(customerDetails.kyc_external_state);

  const scrollToError = () => {
    const errorElement = document.querySelector('.error');
    if (errorElement) {
      const yOffset = -50;
      const y = errorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div className={'customer-details-table'}>
      <div style={{ position: 'relative' }}></div>
      <form
        action={'/form'}
        autoComplete={'off'}
        id={'send'}
        onSubmit={handleSubmit}
        className={'customer-details-form'}
      >
        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.subsection.customerDetails')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.customerStatus')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'customerStatus'}
                    name={'customerStatus'}
                    options={customerStatusOptions}
                    value={values.customerStatus}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(ACCOUNT_STATUS_UPDATE)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onChangeCustomerStatus(values.customerStatus)}
                    isDisabled={!hasRole(ACCOUNT_STATUS_UPDATE)}
                  >
                    {i18n.getMessage('customers.individual.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.onboardingStatus')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'onboardingStatus'}
                    name={'onboardingStatus'}
                    options={onboardingStatusOptions}
                    value={values.onboardingStatus}
                    onChange={(name, value) => setFieldValue(name, value)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onChangeOnboardingStatus(values.onboardingStatus)}
                  >
                    {i18n.getMessage('customers.individual.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.customerId')}</td>
              <td className={'table-cell'}>
                <span className={'table-cell-span'}>{customerDetails.account_number}</span>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.customerEmail')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <Input className={'table-cell-input'} name={'email'} value={values.email} onChange={handleChange} />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveEmail(values.email)}
                  >
                    {i18n.getMessage('customers.individual.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.customerRegistrationTime')}
              </td>
              <td className={'table-cell'}>
                <div className={'table-cell-span-wrapper'}>
                  <span className={'table-cell-span'}>{formatDateTime(customerDetails?.user?.registration_date)}</span>
                  <span className={'table-cell-span'}>IP: {customerDetails?.user?.registration_ip}</span>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.firstAccountOpeningDate')}
              </td>
              <td className={'table-cell'}>
                <span className={'table-cell-span'}>
                  {firstCreatedAccount ? formatDateTime(firstCreatedAccount.created_at) : ''}
                </span>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.customerOwner')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <OperatorsAsyncSelect
                    handleValueChanged={(value) => setFieldValue('customerOwner', value)}
                    value={values.customerOwner}
                    error={errors.customerOwner}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.customerSource')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'source'}
                    value={values.source}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.source || fieldsErrors?.source}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.agentName')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'agentName'}
                    value={values.agentName}
                    onChange={handleChange}
                    error={errors?.agentName || fieldsErrors?.agentName}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.subsection.customerContactDetails')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.customerContactEmailAddress')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'contactEmail'}
                    value={values.contactEmail}
                    onChange={handleChange}
                    error={errors?.contactEmail || fieldsErrors?.contactEmail}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.customerContactPhoneNumber')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'contactPhone'}
                    value={values.contactPhone}
                    onChange={handleChange}
                    error={errors?.contactPhone || fieldsErrors?.contactPhone}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.subsection.externalKycProvider')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.kycExternalReference')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'kycExternalReference'}
                    value={values.kycExternalReference}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.kycExternalReference || fieldsErrors?.kycExternalReference}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onChangeExternalKycReference(values.kycExternalReference)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.individual.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.externalKycLink')}
              </td>
              <td className={'table-cell'}>
                {customerDetails?.verification_provider === KYC_PROVIDERS.SUMSUB && externalKycState.identifier && (
                  <span className={'table-cell-span'}>
                    <a
                      className={'link-normal'}
                      href={`${EXTERNAL_KYC_LINK}${externalKycState.identifier}`}
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                    >
                      {`${EXTERNAL_KYC_LINK}${externalKycState.identifier}`}
                    </a>
                  </span>
                )}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.statuses')}</td>
              <td className={'table-cell'}>
                <span className={'table-cell-span'}>{externalKycState.statuses}</span>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.tags')}</td>
              <td className={'table-cell'}>
                <span className={'table-cell-span'}>{externalKycState.tags}</span>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.linkToDocuments')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'googleDriveLink'}
                    value={values.googleDriveLink}
                    onChange={handleChange}
                    error={errors?.googleDriveLink || fieldsErrors?.googleDriveLink}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.subsection.individualDetails')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.personTitle')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'title'}
                    name={'title'}
                    options={titleOptions}
                    value={values.title}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.name')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'name'}
                    value={values.name}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.name || fieldsErrors?.name}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.surname')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'surname'}
                    value={values.surname}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.surname || fieldsErrors?.surname}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.birthday')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    type={'date-mask'}
                    name='birthday'
                    placeholder='YYYY-MM-DD'
                    value={values?.birthday}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.birthday || fieldsErrors?.birthday}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.passportNumber')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'passportNumber'}
                    value={values.passportNumber}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.passportNumber || fieldsErrors?.passportNumber}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.passportIssueCountry')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'passportCountry'}
                    name={'passportCountry'}
                    options={countriesOptions}
                    value={values.passportCountry}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.passportIssueDate')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    type={'date-mask'}
                    name='passportDate'
                    placeholder='YYYY-MM-DD'
                    value={values?.passportDate}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.passportDate || fieldsErrors?.passportDate}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.passportExpiryDate')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    type={'date-mask'}
                    name='passportExpiryDate'
                    placeholder='YYYY-MM-DD'
                    value={values?.passportExpiryDate}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.passportExpiryDate || fieldsErrors?.passportExpiryDate}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.subsection.address')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.address1')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'address1'}
                    value={values.address1}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.address1 || fieldsErrors?.address1}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.address2')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'address2'}
                    value={values.address2}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.address2 || fieldsErrors?.address2}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.zipCode')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'zipCode'}
                    value={values.zipCode}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.zipCode || fieldsErrors?.zipCode}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.city')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'city'}
                    value={values.city}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.city || fieldsErrors?.city}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.country')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'country'}
                    name={'country'}
                    options={countriesOptions}
                    value={values.country}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.subsection.kyc')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.kycStatus')}</td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'kycStatus'}
                    name={'kycStatus'}
                    options={kycStatusOptions}
                    value={values.kycStatus}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveKycStatus(values.kycStatus)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.individual.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.subsection.aml')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.riskProfile')}</td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'riskProfile'}
                    name={'riskProfile'}
                    options={riskProfileOptions}
                    value={values.riskProfile}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveRiskProfile(values.riskProfile)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.individual.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.nextAmlCheckDate')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <CustomDatePicker
                    className={'table-cell-date-input'}
                    name={'nextAMLCheckDate'}
                    value={values.nextAMLCheckDate}
                    onChange={(name, value) => setFieldValue(name, value)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveNextAMLCheckDate(convertDate(values.nextAMLCheckDate))}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.individual.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.internalAmlDocumentsLink')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'internalAmlDocumentsLink'}
                    value={values.internalAmlDocumentsLink}
                    onChange={handleChange}
                    error={errors?.internalAmlDocumentsLink || fieldsErrors?.internalAmlDocumentsLink}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.purposeOfOpeningAnAccount')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'accountOpeningPurpose'}
                    value={values.accountOpeningPurpose}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.accountOpeningPurpose || fieldsErrors?.accountOpeningPurpose}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.isSanctionsRelated')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'sanctionsRelated'}
                    name={'sanctionsRelated'}
                    options={yesOrNoOptions}
                    value={values.sanctionsRelated}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.isAdverseMediaInvolved')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'adverseMediaInvolved'}
                    name={'adverseMediaInvolved'}
                    options={yesOrNoOptions}
                    value={values.adverseMediaInvolved}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.pepList')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'pepList'}
                    name={'pepList'}
                    options={passOrFailOptions}
                    value={values.pepList}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.pepList || fieldsErrors?.pepList}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.mlroApproval')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'mlroApproval'}
                    name={'mlroApproval'}
                    options={approvalOptions}
                    value={values.mlroApproval}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveMlroApproval(values.mlroApproval)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.individual.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.directorApproval')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'directorApproval'}
                    name={'directorApproval'}
                    options={approvalOptions}
                    value={values.directorApproval}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveDirectorApproval(values.directorApproval)}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.individual.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.individual.customerDetailsTab.subsection.relatedCompany')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.individual.customerDetailsTab.position')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'position'}
                    value={values.position}
                    onChange={handleChange}
                    isDisabled={!hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.position || fieldsErrors?.position}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {hasRole(INDIVIDUAL_ACCOUNT_DETAILS_UPDATE) && (
          <div className={'bottom-actions-wrapper'}>
            <Button className={'customers-button'} size={'medium'} type={'primary'} onClick={onReceiveKycData}>
              {i18n.getMessage('customers.individual.customerDetailsTab.button.receiveKycData')}
            </Button>
            <div className={'changes-actions-wrapper'}>
              <Button className={'customers-button'} size={'medium'} type={'outline'} onClick={resetForm}>
                {i18n.getMessage('customers.individual.customerDetailsTab.button.discard')}
              </Button>
              <Button
                className={'customers-button'}
                roleType={'submit'}
                size={'medium'}
                type={'primary'}
                onClick={() => {
                  validateForm(values).then(() => {
                    scrollToError();
                  });
                }}
              >
                {i18n.getMessage('customers.individual.customerDetailsTab.button.saveChanges')}
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

CustomerDetailsTab.propTypes = {
  customerDetails: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  handleUpdateCustomerDetails: PropTypes.func,
  applicationConstants: PropTypes.object,
  fieldsErrors: PropTypes.any,
  onChangeCustomerStatus: PropTypes.func,
  onChangeOnboardingStatus: PropTypes.func,
  onChangeExternalKycReference: PropTypes.func,
  onReceiveKycData: PropTypes.func.isRequired,
  onSaveKycStatus: PropTypes.func.isRequired,
  onSaveNextAMLCheckDate: PropTypes.func.isRequired,
  onSaveMlroApproval: PropTypes.func.isRequired,
  onSaveDirectorApproval: PropTypes.func.isRequired,
  onSaveRiskProfile: PropTypes.func.isRequired,
  onSaveEmail: PropTypes.func.isRequired
};

export default CustomerDetailsTab;
