import { Fragment, useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TRANSIT_TRANSACTION_DIRECTION, TRANSIT_TRANSACTION_STATUS } from 'components/constants';
import i18nContext from 'components/i18n-context';
import { formatDate, formatMoney } from 'services/utils';
import Button from 'uikit/Button/Button';
import './TransitTransactionsTableRow.scss';

const TransitTransactionsTableRow = ({
  isLoading,
  transitTransactionData,
  handleRepeatTransitTransaction,
  handleCreatePaymentTransitTransaction,
  handleReturnTransitTransaction,
  handleDeleteTransitTransaction
}) => {
  const i18n = useContext(i18nContext);

  const getTransitTransactionInfoBlock = (transitTransactionBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'transit-transactions-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (Array.isArray(transitTransactionBlockInfo)) {
      return (
        <p>
          {transitTransactionBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < transitTransactionBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{transitTransactionBlockInfo}</p>;
    }
  };

  const isReturnAvailable = (transitTransaction) => {
    if (transitTransaction.direction === TRANSIT_TRANSACTION_DIRECTION.IN) {
      return transitTransaction.status === TRANSIT_TRANSACTION_STATUS.CREATED;
    } else {
      return transitTransaction.status === TRANSIT_TRANSACTION_STATUS.PROCESSING;
    }
  };

  return (
    <tr>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(transitTransactionData.transaction_id)}
      </td>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(formatMoney(transitTransactionData.amount, transitTransactionData.currency, 2))}
      </td>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(transitTransactionData.currency)}
      </td>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(i18n.getMessage(`transitTransactionStatus.${transitTransactionData.status}`))}
      </td>
      <td className={'transit-transactions-table-cell transit-transactions-status-details-table-cell'}>
        {getTransitTransactionInfoBlock(transitTransactionData.status_details)}
      </td>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(
          i18n.getMessage(`transitTransactionDirection.${transitTransactionData.direction}`)
        )}
      </td>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(transitTransactionData.external_id)}
      </td>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(i18n.getMessage(`paymentProvider.${transitTransactionData.payment_provider}`))}
      </td>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(transitTransactionData.payment_transaction_number)}
      </td>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(formatDate(transitTransactionData.provider_created_at))}
      </td>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(formatDate(transitTransactionData.created_at))}
      </td>
      <td className={'transit-transactions-table-cell'}>
        {getTransitTransactionInfoBlock(formatDate(transitTransactionData.updated_at))}
      </td>
      <td className={'card-authorizations-table-cell'}>
        <div className={'transit-transactions-table-cell-actions'}>
          {transitTransactionData.status === TRANSIT_TRANSACTION_STATUS.FAILED && (
            <div className={'transit-transactions-table-cell-actions'}>
              <Button
                className={'transit-transactions-action-button'}
                onClick={() => handleRepeatTransitTransaction(transitTransactionData.transaction_id)}
                type={'primary'}
                size={'small'}
                fullWidth
                isDisabled={isLoading}
              >
                {i18n.getMessage('transitTransactions.table.action.repeat')}
              </Button>
              <Button
                className={'transit-transactions-action-button'}
                onClick={() => handleCreatePaymentTransitTransaction(transitTransactionData.transaction_id)}
                type={'primary'}
                size={'small'}
                fullWidth
                // isDisabled={isLoading}
                // TODO: Remove when made create transaction page
                isDisabled={true}
              >
                {i18n.getMessage('transitTransactions.table.action.createTransaction')}
              </Button>
            </div>
          )}

          {transitTransactionData.status === TRANSIT_TRANSACTION_STATUS.CREATED && (
            <Button
              className={'transit-transactions-action-button'}
              onClick={() => handleCreatePaymentTransitTransaction(transitTransactionData.transaction_id)}
              type={'primary'}
              size={'small'}
              fullWidth
              // isDisabled={isLoading}
              // TODO: Remove when made create transaction page
              isDisabled={true}
            >
              {i18n.getMessage('transitTransactions.table.action.createTransaction')}
            </Button>
          )}

          {isReturnAvailable(transitTransactionData) && (
            <Button
              className={'transit-transactions-action-button'}
              onClick={() => handleReturnTransitTransaction(transitTransactionData.transaction_id)}
              type={'primary'}
              size={'small'}
              fullWidth
              isDisabled={isLoading}
            >
              {i18n.getMessage('transitTransactions.table.action.return')}
            </Button>
          )}

          {transitTransactionData.status === TRANSIT_TRANSACTION_STATUS.CREATED &&
            transitTransactionData.direction === TRANSIT_TRANSACTION_DIRECTION.IN &&
            !transitTransactionData.payment_transaction_number && (
              <Button
                className={'transit-transactions-action-button'}
                onClick={() => handleDeleteTransitTransaction(transitTransactionData.transaction_id)}
                type={'primary'}
                size={'small'}
                fullWidth
                isDisabled={isLoading}
              >
                {i18n.getMessage('transitTransactions.table.action.delete')}
              </Button>
            )}
        </div>
      </td>
    </tr>
  );
};

TransitTransactionsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  transitTransactionData: PropTypes.object,
  handleRepeatTransitTransaction: PropTypes.func,
  handleCreatePaymentTransitTransaction: PropTypes.func,
  handleReturnTransitTransaction: PropTypes.func,
  handleDeleteTransitTransaction: PropTypes.func
};

export default TransitTransactionsTableRow;
