import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { MonitoringTabHeadRow } from './components/MonitoringTabHeadRow';
import { MonitoringTabTableRow } from './components/MonitoringTabTableRow';
import Loader from 'components/Loader';
import { ROUTE_PATHS } from 'routes/constants';
import './MonitoringTab.scss';

const MonitoringTab = ({ documents, loadDocuments, downloadDocument }) => {
  const navigate = useNavigate();

  useEffect(() => {
    loadDocuments();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenTransactionDetails = (transactionNumber) => {
    navigate(`${ROUTE_PATHS.TRANSACTIONS}/${transactionNumber}`);
  };

  if (!documents) {
    return <Loader className={'application-loader monitoring-tab-loader'} />;
  }

  return (
    <div className={'monitoring-container'}>
      <Table responsive>
        <thead>
          <MonitoringTabHeadRow />
        </thead>
        <tbody>
          {documents.map((data) => (
            <MonitoringTabTableRow
              key={data.document_uid}
              isLoading={false}
              monitoringData={data}
              handleOpenTransactionDetails={handleOpenTransactionDetails}
              downloadDocumentById={downloadDocument}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

MonitoringTab.propTypes = {
  isLoading: PropTypes.bool,
  documents: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  loadDocuments: PropTypes.func,
  downloadDocument: PropTypes.func
};

export default MonitoringTab;
