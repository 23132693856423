import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import i18nContext from 'components/i18n-context';
import { MANUAL_BANK_DETAILS, PAYMENT_PROVIDER } from 'components/constants';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './AddNewAccountScheme.scss';

const AddNewAccountScheme = ({
  isLoading,
  isAddCurrency,
  accountForUpdate,
  currenciesForCreateAccount,
  currenciesForAdd,
  paymentProviders,
  onClose,
  addNewAccount
}) => {
  const i18n = useContext(i18nContext);
  const [currencySelectOptions, setCurrencySelectOptions] = useState([]);

  const form = useFormik({
    initialValues: {
      paymentProvider: '',
      currency: '',
      iban: ''
    },
    onSubmit: async (values) => {
      await addNewAccount(values.paymentProvider, {
        currency: values.currency,
        iban: values.iban,
        ...(accountForUpdate && { multi_wallet_number: accountForUpdate.multi_wallet_number })
      });
    }
  });

  const { values, handleSubmit, setFieldValue, handleChange } = form;

  useEffect(() => {
    if (!values.paymentProvider) {
      setCurrencySelectOptions([]);
    } else {
      setCurrencySelectOptions(
        PAYMENT_PROVIDER[values.paymentProvider].currencies.map((currency) => {
          return {
            key: currency,
            value: currency
          };
        })
      );
    }
  }, [values.paymentProvider]);

  const currencyOptions = isAddCurrency
    ? [...currenciesForCreateAccount, ...currenciesForAdd].filter(
        (currency) => !accountForUpdate.existingCurrencies.includes(currency)
      )
    : values.paymentProvider === PAYMENT_PROVIDER.WALLESTER.name
      ? PAYMENT_PROVIDER.WALLESTER.currencies
      : currenciesForCreateAccount;

  const paymentProviderOptions = paymentProviders.map((provider) => {
    return {
      key: provider,
      value: i18n.getMessage('paymentProvider.' + provider)
    };
  });

  const manualEurIbanOptions = Object.keys(MANUAL_BANK_DETAILS).map((method) => {
    return {
      key: MANUAL_BANK_DETAILS[method],
      value: MANUAL_BANK_DETAILS[method]
    };
  });

  const handleChangeValue = (name, value) => {
    return setFieldValue(name, value);
  };

  return (
    <div className={'scheme-wrapper scheme-wrapper-add-new-account'}>
      <span className={'scheme-success-header scheme-header-add-new-account'}>
        {isAddCurrency
          ? i18n.getMessage('accountsTab.modal.addNewCurrency.header')
          : i18n.getMessage('accountsTab.modal.addNewAccount.header')}
      </span>

      <form onSubmit={handleSubmit} className={'form-add-new-account-scheme'}>
        <InputDropDown
          className={'table-cell-input'}
          id={'paymentProvider'}
          name={'paymentProvider'}
          label={i18n.getMessage('accountsTab.modal.addNewAccount.input.paymentProvider')}
          options={paymentProviderOptions}
          value={values.paymentProvider}
          onChange={handleChangeValue}
        />
        <InputDropDown
          className={'table-cell-input'}
          id={'currency'}
          name={'currency'}
          label={i18n.getMessage('accountsTab.modal.addNewAccount.input.currency')}
          options={currencySelectOptions}
          value={values.currency}
          onChange={handleChangeValue}
        />
        {(values.paymentProvider === PAYMENT_PROVIDER.CLEAR_JUNCTION.name ||
          values.paymentProvider === PAYMENT_PROVIDER.RYVYL.name) && (
          <Input
            className={'table-cell-input'}
            id={'iban'}
            name={'iban'}
            label={i18n.getMessage('accountsTab.modal.addNewAccount.input.iban')}
            value={values.iban}
            onChange={handleChange}
          />
        )}
        {values.paymentProvider === PAYMENT_PROVIDER.MANUAL.name && values.currency === 'EUR' && (
          <InputDropDown
            className={'table-cell-input'}
            id={'iban'}
            name={'iban'}
            label={i18n.getMessage('accountsTab.modal.addNewAccount.input.iban')}
            options={manualEurIbanOptions}
            value={values.iban}
            onChange={handleChangeValue}
          />
        )}

        <div className={'scheme-wrapper-buttons add-new-account-scheme-buttons-wrapper'}>
          <Button className={'scheme-button'} onClick={onClose} type={'outline'} isDisabled={isLoading}>
            {i18n.getMessage('accountsTab.modal.addNewAccount.action.back')}
          </Button>
          <Button
            roleType={'submit'}
            className={'scheme-button'}
            isDisabled={
              isLoading || values.paymentProvider === PAYMENT_PROVIDER.CLEAR_JUNCTION.name
                ? !values.iban
                : !values.currency
            }
          >
            {isLoading ? <Loader /> : i18n.getMessage('accountsTab.modal.addNewAccount.action.create')}
          </Button>
        </div>
      </form>
    </div>
  );
};

AddNewAccountScheme.propTypes = {
  isLoading: PropTypes.bool,
  isAddCurrency: PropTypes.bool,
  accountForUpdate: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  currenciesForCreateAccount: PropTypes.array,
  currenciesForAdd: PropTypes.array,
  paymentProviders: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  addNewAccount: PropTypes.func.isRequired
};

export default AddNewAccountScheme;
