import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { DIRECTION } from 'components/constants';
import i18n from 'i18n';
import {
  acceptOnHoldTransactionRequest,
  acceptProcessingTransactionRequest,
  addTransactionNoteRequest,
  changeTransactionDocumentStatusRequest,
  deleteNoteRequest,
  deletePaymentTransactionDocumentRequest,
  downloadNoteDocumentRequest,
  downloadPaymentTransactionDocumentRequest,
  exportIfxTransactionListRequest,
  exportTransactionListRequest,
  getTransactionNotesRequest,
  getTransactionRequest,
  getTransactionsListRequest,
  rejectTransactionRequest,
  returnTransactionRequest,
  updateTransactionMlroApprovalRequest,
  uploadPaymentTransactionDocumentRequest
} from 'services/requestAgent';
import { convertDate, convertToFullDate } from 'services/utils';

class TransactionsStore {
  isInitialized = false;
  isLoading = false;
  error = null;
  transactionList = [];
  currentTransactionDetails = null;
  currentTransactionNotes = null;
  totalElements = null;
  sortColumn = {
    sort_column: 'created_at',
    sort_direction: DIRECTION.DESC
  };
  pagination = {
    size: 20,
    page: 0,
    totalPages: null
  };
  filters = {
    search_text: null,
    from_date: null,
    to_date: null,
    transfer_providers: [],
    types: [],
    statuses: [],
    currencies: [],
    payment_methods: [],
    transfer_types: [],
    document_statuses: []
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetTransactionStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.error = null;
    this.transactionList = [];
    this.currentTransactionDetails = null;
    this.currentTransactionNotes = null;
    this.totalElements = null;
    this.sortColumn = {
      sort_column: 'created_at',
      sort_direction: DIRECTION.DESC
    };
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
    this.filters = {
      search_text: null,
      from_date: null,
      to_date: null,
      transfer_providers: [],
      types: [],
      statuses: [],
      currencies: [],
      payment_methods: [],
      transfer_types: [],
      document_statuses: []
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setPageNumber = (page) => {
    this.pagination.page = page;
  };

  setPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.sortColumn.sort_column = sortData.sortBy;
    this.sortColumn.sort_direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    const filters = { ...this.filters };
    const pagination = { ...this.pagination };

    // Mapping URL filter parameters to corresponding properties in the 'filters' object
    const filterParamsMapping = {
      search_text: 'search_text',
      from_date: 'from_date',
      to_date: 'to_date',
      transfer_providers: 'transfer_providers',
      types: 'types',
      statuses: 'statuses',
      currencies: 'currencies',
      payment_methods: 'payment_methods',
      transfer_types: 'transfer_types',
      document_statuses: 'document_statuses'
    };

    // Iterating over each URL parameter and assigning its values to 'filters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        // Convert `from_date` and `to_date` if they exist
        if (param === 'from_date' || param === 'to_date') {
          value = convertToFullDate(value);
        }

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    // Assigning pagination parameters from the URL
    pagination.page = parseInt(params.page) || pagination.page;
    pagination.size = parseInt(params.size) || pagination.size;

    // Assigning sorting parameters from the URL
    this.sortColumn.sort_column = params.sort_column || this.sortColumn.sort_column;
    this.sortColumn.sort_direction = params.sort_direction || this.sortColumn.sort_direction;

    // Updating the state of filters and pagination
    this.filters = filters;
    this.pagination = pagination;
  };

  prepareFiltersParams = () => {
    const params = { ...this.filters };

    if (this.filters.from_date) {
      params.from_date = convertDate(this.filters.from_date);
    } else {
      delete params.from_date;
    }

    if (this.filters.to_date) {
      params.to_date = convertDate(this.filters.to_date);
    } else {
      delete params.to_date;
    }

    return params;
  };

  getTransactionList = async () => {
    this.setIsLoading(true);
    try {
      const filtersParams = this.prepareFiltersParams();

      const res = await getTransactionsListRequest(
        this.pagination,
        this.sortColumn.sort_column,
        this.sortColumn.sort_direction,
        filtersParams.search_text,
        filtersParams
      );

      runInAction(() => {
        this.isInitialized = true;
        this.transactionList = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  exportTransactionList = async () => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(exportTransactionListRequest(this.prepareFiltersParams()), {
      pending: i18n.getMessage('transactionsStore.exportTransactionList.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.exportTransactionList.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  exportIfxTransactionList = async () => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(exportIfxTransactionListRequest(this.prepareFiltersParams()), {
      pending: i18n.getMessage('transactionsStore.exportIfxTransactionList.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.exportIfxTransactionList.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getTransaction = async (transactionNumber) => {
    this.setIsLoading(true);
    try {
      const transactionDetails = await getTransactionRequest(transactionNumber);

      runInAction(() => {
        this.currentTransactionDetails = transactionDetails;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  rejectTransaction = async (transactionNumber) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(rejectTransactionRequest(transactionNumber), {
      pending: i18n.getMessage('transactionsStore.rejectTransaction.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.rejectTransaction.toastPromise.success')
    });

    try {
      const transactionDetails = await toastPromise;

      runInAction(() => {
        this.currentTransactionDetails = transactionDetails;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  returnTransaction = async (transactionNumber) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(returnTransactionRequest(transactionNumber), {
      pending: i18n.getMessage('transactionsStore.returnTransaction.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.returnTransaction.toastPromise.success')
    });

    try {
      const transactionDetails = await toastPromise;

      runInAction(() => {
        this.currentTransactionDetails = transactionDetails;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  acceptOnHoldTransaction = async (transactionNumber) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(acceptOnHoldTransactionRequest(transactionNumber), {
      pending: i18n.getMessage('transactionsStore.acceptOnHoldTransaction.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.acceptOnHoldTransaction.toastPromise.success')
    });

    try {
      const transactionDetails = await toastPromise;

      runInAction(() => {
        this.currentTransactionDetails = transactionDetails;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  acceptProcessingTransaction = async (transactionNumber) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(acceptProcessingTransactionRequest(transactionNumber), {
      pending: i18n.getMessage('transactionsStore.acceptProcessingTransaction.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.acceptProcessingTransaction.toastPromise.success')
    });

    try {
      const transactionDetails = await toastPromise;

      runInAction(() => {
        this.currentTransactionDetails = transactionDetails;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  changeTransactionDocumentStatus = async (transactionNumber, status) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(changeTransactionDocumentStatusRequest(transactionNumber, status), {
      pending: i18n.getMessage('transactionsStore.changeTransactionDocumentStatus.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.changeTransactionDocumentStatus.toastPromise.success')
    });

    try {
      const transactionDetails = await toastPromise;

      runInAction(() => {
        this.currentTransactionDetails = transactionDetails;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  updateTransactionMlroApproval = async (transactionNumber, approvalStatus) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateTransactionMlroApprovalRequest(transactionNumber, approvalStatus), {
      pending: i18n.getMessage('transactionsStore.updateTransactionMlroApproval.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.updateTransactionMlroApproval.toastPromise.success')
    });

    try {
      const mlroApprovalData = await toastPromise;

      runInAction(() => {
        this.currentTransactionDetails.mlroApproval = mlroApprovalData;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  setDeleteAvailability = (note) => {
    const deleteAvailable = note.deleteExpiresIn > 0;
    note.deleteAvailable = deleteAvailable;

    if (deleteAvailable) {
      setTimeout(() => {
        runInAction(() => {
          this.currentTransactionNotes = this.currentTransactionNotes.map((el) =>
            el.id === note.id ? { ...el, deleteAvailable: false } : el
          );
        });
      }, note.deleteExpiresIn * 1000);
    }

    return note;
  };

  getTransactionNotes = async (transactionNumber) => {
    this.setIsLoading(true);
    try {
      const customerNotes = await getTransactionNotesRequest(transactionNumber);

      const checkedNotes = customerNotes.map(this.setDeleteAvailability);

      runInAction(() => {
        this.currentTransactionNotes = checkedNotes;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  addTransactionNote = async (customerNumber, transactionNumber, note, assigneeId, documents) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(
      addTransactionNoteRequest(customerNumber, transactionNumber, note, assigneeId, documents),
      {
        pending: i18n.getMessage('transactionsStore.addTransactionNote.toastPromise.pending'),
        success: i18n.getMessage('transactionsStore.addTransactionNote.toastPromise.success')
      }
    );

    try {
      const newNote = await toastPromise;

      const checkedNote = this.setDeleteAvailability(newNote);

      runInAction(() => {
        this.currentTransactionNotes = [checkedNote, ...this.currentTransactionNotes];
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  deleteTransactionNote = async (noteId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteNoteRequest(noteId), {
      pending: i18n.getMessage('transactionsStore.deleteTransactionNote.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.deleteTransactionNote.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.currentTransactionNotes = this.currentTransactionNotes.filter((note) => note.id !== noteId);
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  downloadTransactionNoteDocument = async (documentId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(downloadNoteDocumentRequest(documentId), {
      pending: i18n.getMessage('transactionsStore.downloadNoteDocument.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.downloadNoteDocument.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  uploadPaymentTransactionDocument = async (transactionNumber, file) => {
    this.setIsLoading(true);
    try {
      const attachedDocumentInfo = await uploadPaymentTransactionDocumentRequest(transactionNumber, file);

      runInAction(() => {
        this.currentTransactionDetails.documents = [...this.currentTransactionDetails.documents, attachedDocumentInfo];
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  downloadPaymentTransactionDocument = async (documentId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(downloadPaymentTransactionDocumentRequest(documentId), {
      pending: i18n.getMessage('transactionsStore.downloadPaymentTransactionDocument.toastPromise.pending'),
      success: i18n.getMessage('transactionsStore.downloadPaymentTransactionDocument.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  deletePaymentTransactionDocument = async (documentId) => {
    this.setIsLoading(true);
    try {
      await deletePaymentTransactionDocumentRequest(documentId);

      const updatedDocuments = this.currentTransactionDetails.documents.filter(
        (document) => document.id !== documentId
      );

      runInAction(() => {
        this.currentTransactionDetails.documents = updatedDocuments;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };
}

export default new TransactionsStore();
