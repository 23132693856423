import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import { ReactComponent as CreateUserIcon } from 'assets/iconButtons/users.svg';
import i18nContext from 'components/i18n-context';
import { CUSTOMER_STATUSES, CUSTOMER_TYPE, KYC_STATUSES, ONBOARDING_STATUS, RISK_PROFILE } from 'components/constants';
import CustomSearchInput from 'components/CustomSearchInput';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './CustomersFiltersBar.scss';

const CustomersFiltersBar = ({
  isInitialized,
  isShowActiveCustomers,
  isLoadingCustomerCsv,
  isLoadingBalancesCsv,
  admins,
  filters,
  totalElements,
  handleExportCustomers,
  handleExportBalances,
  handleCreateUserButtonClick,
  handleSetFilters
}) => {
  const i18n = useContext(i18nContext);
  const [searchValue, setSearchValue] = useState(filters.search_text ? filters.search_text : '');
  const currentDate = new Date();

  const debouncedSearch = useRef(
    debounce((name, value) => {
      handleSetFilters(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const customerTypeOptions = Object.keys(CUSTOMER_TYPE).map((status) => {
    return {
      key: CUSTOMER_TYPE[status],
      value: i18n.getMessage('customers.type.' + CUSTOMER_TYPE[status])
    };
  });

  const customerStatusOptions = Object.keys(CUSTOMER_STATUSES).map((status) => {
    return {
      key: CUSTOMER_STATUSES[status],
      value: i18n.getMessage('customers.status.' + CUSTOMER_STATUSES[status])
    };
  });

  const onboardingStatusOptions = Object.keys(ONBOARDING_STATUS).map((status) => {
    return {
      key: ONBOARDING_STATUS[status],
      value: i18n.getMessage('onboardingStatus.' + ONBOARDING_STATUS[status])
    };
  });

  const kycStatusOptions = (
    isShowActiveCustomers
      ? Object.keys(KYC_STATUSES).filter((s) => s === KYC_STATUSES.VERIFIED)
      : Object.keys(KYC_STATUSES).filter((s) => s !== KYC_STATUSES.VERIFIED)
  ).map((status) => ({
    key: KYC_STATUSES[status],
    value: i18n.getMessage('kyc.status.' + KYC_STATUSES[status])
  }));

  const customerOwnersOptions = admins.map((admin) => {
    return {
      key: admin.id + '',
      value: admin.name
    };
  });

  const riskProfileOptions = Object.keys(RISK_PROFILE).map((status) => {
    return {
      key: RISK_PROFILE[status],
      value: i18n.getMessage('customers.riskProfile.' + RISK_PROFILE[status])
    };
  });

  return (
    <div className={'customers-filters-wrapper'}>
      <div className={'customers-filters-top-bar-wrapper'}>
        {!isShowActiveCustomers && (
          <div className={'customers-date-range-filters-wrapper'}>
            <div>
              <span>{i18n.getMessage('customers.filter.from.label')}</span>
              <CustomDatePicker
                onChange={handleSetFilters}
                name={'from_date'}
                value={filters.from_date}
                maxDate={filters.to_date || currentDate}
              />
            </div>
            <div>
              <span>{i18n.getMessage('customers.filter.to.label')}</span>
              <CustomDatePicker
                onChange={handleSetFilters}
                name={'to_date'}
                value={filters.to_date}
                maxDate={currentDate}
                minDate={filters.from_date}
              />
            </div>
          </div>
        )}

        <div
          className={'customers-filters-top-bar-actions-wrapper'}
          style={isShowActiveCustomers ? { width: '100%', justifyContent: 'flex-end' } : {}}
        >
          <Button
            className={'customers-button'}
            size={'medium'}
            type={'primary'}
            onClick={handleExportCustomers}
            isDisabled={!isInitialized || isLoadingCustomerCsv}
          >
            {isLoadingCustomerCsv ? <Loader /> : i18n.getMessage('customers.button.customersCsv')}
          </Button>

          {/* Hidden due to task TRN-4653 */}
          {/*<Button*/}
          {/*  className={'customers-button'}*/}
          {/*  size={'medium'}*/}
          {/*  type={'primary'}*/}
          {/*  onClick={handleExportBalances}*/}
          {/*  isDisabled={!isInitialized || isLoadingBalancesCsv}*/}
          {/*>*/}
          {/*  {isLoadingBalancesCsv ? <Loader /> : i18n.getMessage('customers.button.balancesCsv')}*/}
          {/*</Button>*/}
          {/* Hidden due to task TRN-4653 */}

          <Button className={'customers-button'} size={'medium'} type={'primary'} onClick={handleCreateUserButtonClick}>
            <CreateUserIcon />
            {i18n.getMessage('customers.button.createUser')}
          </Button>
        </div>
      </div>

      <InputDropDown
        className={'filter-wrapper'}
        name={'types'}
        label={i18n.getMessage('customers.filter.customerType.label')}
        value={filters.types}
        options={customerTypeOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        name={'onboarding_statuses'}
        label={i18n.getMessage('customers.filter.onboardingStatus.label')}
        value={filters.onboarding_statuses}
        options={onboardingStatusOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('customers.filter.customerStatus.label')}
        name={'account_statuses'}
        value={filters.account_statuses}
        options={customerStatusOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('customers.filter.kycStatus.label')}
        name={'kyc_statuses'}
        value={filters.kyc_statuses}
        options={kycStatusOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        name={'customer_owners'}
        label={i18n.getMessage('customers.filter.customerOwner.label')}
        value={filters.customer_owners}
        options={customerOwnersOptions}
        onChange={handleSetFilters}
        isMulti
      />
      {isShowActiveCustomers && (
        <InputDropDown
          className={'filter-wrapper'}
          label={i18n.getMessage('customers.filter.riskProfile.label')}
          name={'risk_profiles'}
          value={filters.risk_profiles}
          options={riskProfileOptions}
          onChange={handleSetFilters}
          isMulti
        />
      )}
      <CustomSearchInput
        wrapperClassName={'search-wrapper'}
        inputClassName={'filter-wrapper'}
        label={i18n.getMessage('customers.filter.search.label')}
        placeholder={i18n.getMessage('customers.filter.search.placeholder')}
        name={'search_text'}
        value={searchValue}
        onChange={handleSearchTextInputChange}
        totalElements={totalElements}
      />
    </div>
  );
};

CustomersFiltersBar.propTypes = {
  isInitialized: PropTypes.bool,
  isShowActiveCustomers: PropTypes.bool,
  isLoadingCustomerCsv: PropTypes.bool,
  isLoadingBalancesCsv: PropTypes.bool,
  admins: PropTypes.array,
  filters: PropTypes.object,
  totalElements: PropTypes.number,
  handleExportCustomers: PropTypes.func,
  handleExportBalances: PropTypes.func,
  handleCreateUserButtonClick: PropTypes.func,
  handleSetFilters: PropTypes.func
};

export default CustomersFiltersBar;
