import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CorporateCustomerDetailsModel from '../CorporateCustomerDetailsModel';
import {
  APPROVAL_STATUS,
  EXTERNAL_KYC_LINK,
  INDUSTRY_TYPES,
  KYC_PROVIDERS,
  ONBOARDING_STATUS,
  RISK_PROFILE,
  YES_OR_NO
} from 'components/constants';
import countries from 'components/countries';
import i18nContext from 'components/i18n-context';
import OperatorsAsyncSelect from 'components/OperatorsAsyncSelect';
import { useApplicationContext } from 'contexts/ApplicationContext';
import { ACCOUNT_STATUS_UPDATE, CORPORATE_ACCOUNT_DETAILS_UPDATE, hasRole } from 'services/roles';
import {
  convertDate,
  formatDateTime,
  countryCodeRegExp,
  emailRegExp,
  parseExternalKycState,
  regularFieldRegExp
} from 'services/utils';
import AmountInput from 'uikit/AmountInput';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './CorporateCustomerDetailsTab.scss';

const CorporateCustomerDetailsTab = ({
  customerDetails,
  fieldsErrors,
  companyInfo,
  handleUpdateCustomerDetails,
  onChangeCustomerStatus,
  onChangeOnboardingStatus,
  onSaveEmail,
  onSaveKycStatus,
  onChangeExternalKycReference,
  onSaveRiskProfile,
  onSaveMlroApproval,
  onSaveDirectorApproval,
  onSaveNextAMLCheckDate,
  onReceiveKycData
}) => {
  const i18n = useContext(i18nContext);
  const { constants, configuration } = useApplicationContext();

  const customerStatusOptions = constants?.accountStatuses.map((value) => ({
    key: value,
    value: i18n.getMessage(`customers.status.${value}`)
  }));

  const onboardingStatusOptions = Object.keys(ONBOARDING_STATUS).map((status) => {
    return {
      key: ONBOARDING_STATUS[status],
      value: i18n.getMessage('onboardingStatus.' + ONBOARDING_STATUS[status])
    };
  });

  const countriesOptions = Object.values(countries).map((value) => ({
    key: value,
    value: i18n.getMessage(value)
  }));

  const kycStatusOptions = constants?.kycStatuses.map((value) => ({
    key: value,
    value: i18n.getMessage(`kyc.status.${value}`)
  }));

  const riskProfileOptions = Object.values(RISK_PROFILE).map((value) => ({
    key: value,
    value: i18n.getMessage(`customers.riskProfile.${value}`)
  }));

  const yesOrNoOptions = Object.values(YES_OR_NO).map((value) => ({
    key: value,
    value: i18n.getMessage(`customers.yesOrNoSelect.${value}`)
  }));

  const approvalOptions = Object.values(APPROVAL_STATUS).map((value) => ({
    key: value,
    value: i18n.getMessage(`customers.approvalStatus.${value}`)
  }));

  const legalEntityTypesOptions = constants?.legalEntityTypes.map((value) => ({
    key: value,
    value: i18n.getMessage(`legalEntityType.${value}`)
  }));

  const industryTypesOptions = Object.values(INDUSTRY_TYPES).map((value) => ({
    key: value,
    value: i18n.getMessage(`industryType.${value}`)
  }));

  const requiredFieldErrorMessage = i18n.getMessage('customer.validation.isRequired');
  const invalidFormatErrorMessage = i18n.getMessage('customer.validation.invalidFormat');
  const invalidCountryCodeErrorMessage = i18n.getMessage('customer.validation.invalidCountryCode');

  const addressValidationSchema = Yup.object().shape({
    address1: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 })),
    address2: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 })),
    zipCode: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(10, i18n.getMessage('customer.validation.maxSize', { size: 10 })),
    city: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 })),
    country: Yup.string().length(2).matches(countryCodeRegExp, invalidCountryCodeErrorMessage).uppercase()
  });

  const validationSchema = Yup.object().shape({
    source: Yup.string()
      .nullable()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(250, i18n.getMessage('customer.validation.maxSize', { size: 250 })),
    companyName: Yup.string()
      .required(requiredFieldErrorMessage)
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 })),
    registrationNumber: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .required(requiredFieldErrorMessage)
      .max(20, i18n.getMessage('customer.validation.maxSize', { size: 20 })),
    jurisdictionCode: Yup.string().max(10, i18n.getMessage('customer.validation.maxSize', { size: 10 })),
    entityLegalType: Yup.string().required(requiredFieldErrorMessage),
    registrationDate: Yup.date().required(requiredFieldErrorMessage),
    legalAddress: addressValidationSchema,
    businessAddress: addressValidationSchema,
    webSite: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 })),
    businessNature: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 })),
    declaredAccountTurnover: Yup.string()
      .matches(regularFieldRegExp, invalidFormatErrorMessage)
      .max(10, i18n.getMessage('customer.validation.maxSize', { size: 10 })),
    nextAMLCheckDate: Yup.date().nullable(),
    industryType: Yup.string().required(requiredFieldErrorMessage),
    accountOpeningPurpose: Yup.string()
      .required(requiredFieldErrorMessage)
      .max(255, i18n.getMessage('customer.validation.maxSize', { size: 255 })),
    customerOwner: Yup.object().required(),
    agentName: Yup.string()
      .notRequired()
      .max(255, i18n.getMessage('customer.validation.maxSize', { size: 255 })),
    contactPhone: Yup.string()
      .notRequired()
      .max(25, i18n.getMessage('customer.validation.maxSize', { size: 25 })),
    contactEmail: Yup.string()
      .notRequired()
      .max(50, i18n.getMessage('customer.validation.maxSize', { size: 50 }))
      .trim()
      .matches(emailRegExp, { message: i18n.getMessage('customer.validation.invalidEmail') }),
    googleDriveLink: Yup.string()
      .notRequired()
      .max(255, i18n.getMessage('customer.validation.maxSize', { size: 255 })),
    internalAmlDocumentsLink: Yup.string()
      .notRequired()
      .max(255, i18n.getMessage('customer.validation.maxSize', { size: 255 }))
  });

  const form = useFormik({
    enableReinitialize: false,
    validateOnChange: false,
    initialValues: {
      email: customerDetails.email || '',
      source: customerDetails.source,
      companyName: companyInfo.companyName,
      registrationNumber: companyInfo.registrationNumber,
      jurisdictionCode: companyInfo.jurisdictionCode,
      entityLegalType: companyInfo.entityLegalType,
      registrationDate: companyInfo.registrationDate ? new Date(companyInfo.registrationDate) : '',
      legalAddress: CorporateCustomerDetailsModel.address(customerDetails.legal_address || {}),
      businessAddress: CorporateCustomerDetailsModel.address(customerDetails.business_address || {}),
      webSite: companyInfo.webSite,
      businessNature: companyInfo.businessNature,
      declaredAccountTurnover: customerDetails.declaredAccountTurnover,
      partners: customerDetails.partners,
      additionalComments: customerDetails.additionalComments,
      commentToDocuments: customerDetails.commentDocuments,
      licence: customerDetails.licence,
      sanctionList: customerDetails.sanctionList || 'PASS',
      blackList: customerDetails.blacklist || 'PASS',
      sanctionsRelated: customerDetails.sanctions_related,
      adverseMediaInvolved: customerDetails.adverse_media_involved,
      nextAMLCheckDate: customerDetails.next_aml_check_date ? new Date(customerDetails.next_aml_check_date) : '',
      industryType: customerDetails.industry_type,
      accountOpeningPurpose: customerDetails.account_opening_purpose,
      customerOwner: customerDetails?.customer_owner || '',
      agentName: customerDetails?.agent_name || '',
      customerStatus: customerDetails?.status,
      kycStatus: customerDetails?.kyc_status,
      kycExternalReference: customerDetails?.kycExternalReference || '',
      riskProfile: customerDetails?.risk_profile || '',
      mlroApproval: customerDetails?.mlro_approval || '',
      directorApproval: customerDetails?.director_approval || '',
      onboardingStatus: customerDetails.onboardingStatus || '',
      contactPhone: customerDetails.contactPhone || customerDetails.user.phone || '',
      contactEmail: customerDetails.contactEmail || customerDetails.email || '',
      googleDriveLink: customerDetails.googleDriveLink || '',
      internalAmlDocumentsLink: customerDetails.internalAmlDocumentsLink || ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleUpdateCustomerDetails({
        ...CorporateCustomerDetailsModel.commonInformationToServer(values),
        business_address: CorporateCustomerDetailsModel.addressToServer(values.businessAddress),
        legal_address: CorporateCustomerDetailsModel.addressToServer(values.legalAddress),
        declaredAccountTurnover: values.declaredAccountTurnover,
        partners: values.partners,
        additionalComments: values.additionalComments,
        commentToDocuments: values.commentDocuments,
        licence: values.licence,
        sanctionList: values.sanctionList || 'PASS',
        blackList: values.blacklist || 'PASS',
        source: values.source,
        sanctionsRelated: values.sanctionsRelated,
        adverseMediaInvolved: values.adverseMediaInvolved,
        customerOwner: values.customerOwner ? values.customerOwner.id : null,
        agentName: values.agentName,
        contactEmail: values.contactEmail,
        contactPhone: values.contactPhone
      });
    }
  });

  const { values, errors, handleSubmit, handleChange, setFieldValue, validateForm, resetForm } = form;
  const firstCreatedAccount =
    customerDetails?.wallets && customerDetails?.wallets.length
      ? customerDetails?.wallets.sort((a, b) => a.created_at - b.created_at)[0]
      : undefined;
  const externalKycState = parseExternalKycState(customerDetails.kyc_external_state);

  //TODO: What is the block and how it need to be display
  /// const payment = this.props.data.payment;
  // const {
  //   companyInfo,
  //   legalAddress,
  //   businessAddress,
  //   incomingPartners,
  //   outgoingPartners,
  //   turnovers
  // } = this.state;
  //TODO: What is the block and how it need to be display

  const scrollToError = () => {
    const errorElement = document.querySelector('.error');
    if (errorElement) {
      const yOffset = -50;
      const y = errorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div className={'customer-details-table'}>
      <div style={{ position: 'relative' }}></div>
      <form
        action={'/form'}
        autoComplete={'off'}
        id={'send'}
        onSubmit={handleSubmit}
        className={'customer-details-form'}
      >
        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.subsection.customerDetails')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.customerStatus')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'customerStatus'}
                    name={'customerStatus'}
                    options={customerStatusOptions}
                    value={values.customerStatus}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(ACCOUNT_STATUS_UPDATE)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onChangeCustomerStatus(values.customerStatus)}
                    isDisabled={!hasRole(ACCOUNT_STATUS_UPDATE)}
                  >
                    {i18n.getMessage('customers.corporate.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.onboardingStatus')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'onboardingStatus'}
                    name={'onboardingStatus'}
                    options={onboardingStatusOptions}
                    value={values.onboardingStatus}
                    onChange={(name, value) => setFieldValue(name, value)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onChangeOnboardingStatus(values.onboardingStatus)}
                  >
                    {i18n.getMessage('customers.corporate.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.customerId')}</td>
              <td className={'table-cell'}>
                <span className={'table-cell-span'}>{customerDetails.account_number}</span>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.customerEmail')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <Input className={'table-cell-input'} name={'email'} value={values.email} onChange={handleChange} />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveEmail(values.email)}
                  >
                    {i18n.getMessage('customers.corporate.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.customerRegistrationTime')}
              </td>
              <td className={'table-cell'}>
                <div className={'table-cell-span-wrapper'}>
                  <span className={'table-cell-span'}>{formatDateTime(customerDetails?.user?.registration_date)}</span>
                  <span className={'table-cell-span'}>IP: {customerDetails?.user?.registration_ip}</span>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.firstAccountOpeningDate')}
              </td>
              <td className={'table-cell'}>
                <span className={'table-cell-span'}>
                  {firstCreatedAccount ? formatDateTime(firstCreatedAccount.created_at) : ''}
                </span>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.customerOwner')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <OperatorsAsyncSelect
                    handleValueChanged={(value) => setFieldValue('customerOwner', value)}
                    value={values.customerOwner}
                    error={errors.customerOwner}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.customerSource')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'source'}
                    value={values.source}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.source || fieldsErrors?.source}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.agentName')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'agentName'}
                    value={values.agentName}
                    onChange={handleChange}
                    error={errors?.agentName || fieldsErrors?.agentName}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.subsection.customerContactDetails')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.customerContactEmailAddress')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'contactEmail'}
                    value={values.contactEmail}
                    onChange={handleChange}
                    error={errors?.contactEmail || fieldsErrors?.contactEmail}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.customerContactPhoneNumber')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'contactPhone'}
                    value={values.contactPhone}
                    onChange={handleChange}
                    error={errors?.contactPhone || fieldsErrors?.contactPhone}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.subsection.externalKycProvider')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.kycExternalReference')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'kycExternalReference'}
                    value={values.kycExternalReference}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.kycExternalReference || fieldsErrors?.kycExternalReference}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onChangeExternalKycReference(values.kycExternalReference)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.corporate.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.externalKycLink')}
              </td>
              <td className={'table-cell'}>
                {customerDetails?.verification_provider === KYC_PROVIDERS.SUMSUB && externalKycState.identifier && (
                  <span className={'table-cell-span'}>
                    <a
                      className={'link-normal'}
                      href={`${EXTERNAL_KYC_LINK}${externalKycState.identifier}`}
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                    >
                      {`${EXTERNAL_KYC_LINK}${externalKycState.identifier}`}
                    </a>
                  </span>
                )}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.statuses')}</td>
              <td className={'table-cell'}>
                <span className={'table-cell-span'}>{externalKycState.statuses}</span>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.tags')}</td>
              <td className={'table-cell'}>
                <span className={'table-cell-span'}>{externalKycState.tags}</span>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.linkToDocuments')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'googleDriveLink'}
                    value={values.googleDriveLink}
                    onChange={handleChange}
                    error={errors?.googleDriveLink || fieldsErrors?.googleDriveLink}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.subsection.companyDetails')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.companyName')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'companyName'}
                    value={values.companyName}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.companyName || fieldsErrors?.companyName}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.registrationNumber')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'registrationNumber'}
                    value={values.registrationNumber}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.registrationNumber || fieldsErrors?.registrationNumber}
                  />
                </div>
              </td>
            </tr>
            {configuration?.parametersByKey['finmatic.kyb.provider'] === KYC_PROVIDERS.SHUFTI && (
              <tr className={'table-row'}>
                <td className={'table-cell'}>
                  {i18n.getMessage('customers.corporate.customerDetailsTab.jurisdictionCode')}
                </td>
                <td className={'table-cell'}>
                  <div className={'customer-details-input-wrapper'}>
                    <Input
                      className={'table-cell-input'}
                      name={'jurisdictionCode'}
                      value={values.jurisdictionCode}
                      onChange={handleChange}
                      isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                      error={errors?.jurisdictionCode || fieldsErrors?.jurisdictionCode}
                    />
                  </div>
                </td>
              </tr>
            )}
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.entityType')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'entityLegalType'}
                    name={'entityLegalType'}
                    options={legalEntityTypesOptions}
                    value={values.entityLegalType}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.entityLegalType || fieldsErrors?.entityLegalType}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.registrationDate')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <CustomDatePicker
                    className={'table-cell-date-input'}
                    name={'registrationDate'}
                    value={values.registrationDate}
                    onChange={(name, value) => setFieldValue(name, value)}
                    error={errors?.registrationDate || fieldsErrors?.registrationDate}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.webSite')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'webSite'}
                    value={values.webSite}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.webSite || fieldsErrors?.webSite}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.businessNature')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'businessNature'}
                    value={values.businessNature}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.businessNature || fieldsErrors?.businessNature}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.industryType')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'industryType'}
                    name={'industryType'}
                    options={industryTypesOptions}
                    value={values.industryType}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.industryType || fieldsErrors?.industryType}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.subsection.addresses')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.legalAddress1')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'legalAddress.address1'}
                    value={values?.legalAddress?.address1}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.legalAddress?.address1 || fieldsErrors?.address1}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.legalAddress2')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'legalAddress.address2'}
                    value={values?.legalAddress?.address2}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.legalAddress?.address2 || fieldsErrors?.address2}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.legalZipCode')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'legalAddress.zipCode'}
                    value={values?.legalAddress?.zipCode}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.legalAddress?.zipCode || fieldsErrors?.zipCode}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.legalCity')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'legalAddress.city'}
                    value={values?.legalAddress?.city}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.legalAddress?.city || fieldsErrors?.city}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.legalCountry')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'legalAddress.country'}
                    name={'legalAddress.country'}
                    options={countriesOptions}
                    value={values?.legalAddress?.country}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.officeAddress1')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'businessAddress.address1'}
                    value={values?.businessAddress?.address1}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.businessAddress?.address1 || fieldsErrors?.address1}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.officeAddress2')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'businessAddress.address2'}
                    value={values?.businessAddress?.address2}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.businessAddress?.address2 || fieldsErrors?.address2}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.officeZipCode')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'businessAddress.zipCode'}
                    value={values?.businessAddress?.zipCode}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.businessAddress?.zipCode || fieldsErrors?.zipCode}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.officeCity')}</td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'businessAddress.city'}
                    value={values?.businessAddress?.city}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.businessAddress?.city || fieldsErrors?.city}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.officeCountry')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'businessAddress.country'}
                    name={'businessAddress.country'}
                    options={countriesOptions}
                    value={values?.businessAddress?.country}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.subsection.kyc')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.kycStatus')}</td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'kycStatus'}
                    name={'kycStatus'}
                    options={kycStatusOptions}
                    value={values.kycStatus}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveKycStatus(values.kycStatus)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.corporate.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td colSpan={'2'} className={'table-cell subsection'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.subsection.aml')}
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.riskProfile')}</td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'riskProfile'}
                    name={'riskProfile'}
                    options={riskProfileOptions}
                    value={values.riskProfile}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveRiskProfile(values.riskProfile)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.corporate.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.nextAmlCheckDate')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <CustomDatePicker
                    className={'table-cell-date-input'}
                    name={'nextAMLCheckDate'}
                    value={values.nextAMLCheckDate}
                    onChange={(name, value) => setFieldValue(name, value)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveNextAMLCheckDate(convertDate(values.nextAMLCheckDate))}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.corporate.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.internalAmlDocumentsLink')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'internalAmlDocumentsLink'}
                    value={values.internalAmlDocumentsLink}
                    onChange={handleChange}
                    error={errors?.internalAmlDocumentsLink || fieldsErrors?.internalAmlDocumentsLink}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.accountOpeningPurpose')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <Input
                    className={'table-cell-input'}
                    name={'accountOpeningPurpose'}
                    value={values.accountOpeningPurpose}
                    onChange={handleChange}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                    error={errors?.accountOpeningPurpose || fieldsErrors?.accountOpeningPurpose}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.declaredAccountTurnover')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <AmountInput
                    className={'table-cell-input'}
                    name={'declaredAccountTurnover'}
                    value={values.declaredAccountTurnover}
                    onChange={handleChange}
                    placeholder={i18n.getMessage(
                      'customers.corporate.customerDetailsTab.declaredAccountTurnover.placeholder'
                    )}
                    error={errors?.declaredAccountTurnover || fieldsErrors?.declaredAccountTurnover}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.isSanctionsRelated')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'sanctionsRelated'}
                    name={'sanctionsRelated'}
                    options={yesOrNoOptions}
                    value={values.sanctionsRelated}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.isAdverseMediaInvolved')}
              </td>
              <td className={'table-cell'}>
                <div className={'customer-details-input-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'adverseMediaInvolved'}
                    name={'adverseMediaInvolved'}
                    options={yesOrNoOptions}
                    value={values.adverseMediaInvolved}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>{i18n.getMessage('customers.corporate.customerDetailsTab.mlroApproval')}</td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'mlroApproval'}
                    name={'mlroApproval'}
                    options={approvalOptions}
                    value={values.mlroApproval}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveMlroApproval(values.mlroApproval)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.corporate.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
            <tr className={'table-row'}>
              <td className={'table-cell'}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.directorApproval')}
              </td>
              <td className={'table-cell'}>
                <div className={'input-with-save-button-wrapper'}>
                  <InputDropDown
                    className={'table-cell-input'}
                    id={'directorApproval'}
                    name={'directorApproval'}
                    options={approvalOptions}
                    value={values.directorApproval}
                    onChange={(name, value) => setFieldValue(name, value)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  />
                  <Button
                    className={'customer-details-tab-button'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => onSaveDirectorApproval(values.directorApproval)}
                    isDisabled={!hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE)}
                  >
                    {i18n.getMessage('customers.corporate.customerDetailsTab.button.save')}
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {/* TODO: What is the block and how it need to be display */}
        {/*{(turnovers.length || incomingPartners.length || outgoingPartners.length || !!payment) &&*/}
        {/*  <table className={'table-wrapper'}>*/}
        {/*    <tbody className={'table-body'}>*/}
        {/*      <tr>*/}
        {/*        <td colSpan={"2"} style={{fontWeight: 'bold', fontSize: 'large'}}>*/}
        {/*          {i18n.getMessage('customer.corporate.subsection.kycDataByUser')}*/}
        {/*        </td>*/}
        {/*      </tr>*/}
        {/*    }*/}
        {/*    {*/}
        {/*      turnovers.map(turnover => (*/}
        {/*        <tr key={turnover.type}>*/}
        {/*          <td style={{verticalAlign: 'middle'}}>*/}
        {/*            {i18n.getMessage(`label.ditails.${turnover.type}`)}*/}
        {/*          </td>*/}
        {/*          <td>*/}
        {/*            {`${turnover.value / 100} ${turnover.currency}`}*/}
        {/*          </td>*/}
        {/*        </tr>*/}
        {/*      ))*/}
        {/*    }*/}
        {/*    {*/}
        {/*      incomingPartners.map((partner, index) => (*/}
        {/*        <TableRowPartners*/}
        {/*          key={partner.name}*/}
        {/*          data={partner}*/}
        {/*          type='incoming'*/}
        {/*          index={index + 1}*/}
        {/*        />*/}
        {/*      ))*/}
        {/*    }*/}
        {/*    {*/}
        {/*      outgoingPartners.map((partner, index) => (*/}
        {/*        <TableRowPartners*/}
        {/*          key={partner.name}*/}
        {/*          data={partner}*/}
        {/*          type='outgoing'*/}
        {/*          index={index + 1}*/}
        {/*        />*/}
        {/*      ))*/}
        {/*    }*/}
        {/*    {*/}
        {/*      payment &&*/}
        {/*      <tr>*/}
        {/*        <td style={{verticalAlign: 'middle'}}>*/}
        {/*          Source of the first payment*/}
        {/*        </td>*/}
        {/*        <td>*/}
        {/*          <p>*/}
        {/*            {`${payment.amount / 100} ${payment.currency} ${payment.payer}`}*/}
        {/*          </p>*/}
        {/*          <p>*/}
        {/*            {payment.purpose}*/}
        {/*          </p>*/}
        {/*        </td>*/}
        {/*      </tr>*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        {/*}*/}
        {/* TODO: What is the block and how it need to be display */}

        {hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE) && (
          <div className={'bottom-actions-wrapper'}>
            <Button className={'customers-button'} size={'medium'} type={'primary'} onClick={onReceiveKycData}>
              {i18n.getMessage('customers.corporate.customerDetailsTab.button.receiveKycData')}
            </Button>
            <div className={'changes-actions-wrapper'}>
              <Button className={'customers-button'} size={'medium'} type={'outline'} onClick={resetForm}>
                {i18n.getMessage('customers.corporate.customerDetailsTab.button.discard')}
              </Button>
              <Button
                className={'customers-button'}
                roleType={'submit'}
                size={'medium'}
                type={'primary'}
                onClick={() => {
                  validateForm(values).then(() => {
                    scrollToError();
                  });
                }}
              >
                {i18n.getMessage('customers.corporate.customerDetailsTab.button.saveChanges')}
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

CorporateCustomerDetailsTab.propTypes = {
  customerDetails: PropTypes.object.isRequired,
  companyInfo: PropTypes.object.isRequired,
  handleUpdateCustomerDetails: PropTypes.func,
  applicationConstants: PropTypes.object,
  fieldsErrors: PropTypes.any,
  onChangeCustomerStatus: PropTypes.func,
  onChangeOnboardingStatus: PropTypes.func,
  onChangeExternalKycReference: PropTypes.func,
  onReceiveKycData: PropTypes.func.isRequired,
  onSaveKycStatus: PropTypes.func.isRequired,
  onSaveNextAMLCheckDate: PropTypes.func.isRequired,
  onSaveMlroApproval: PropTypes.func.isRequired,
  onSaveDirectorApproval: PropTypes.func.isRequired,
  onSaveRiskProfile: PropTypes.func.isRequired,
  onSaveEmail: PropTypes.func.isRequired
};

export default CorporateCustomerDetailsTab;
