import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CUSTOMER_TYPE } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import './CreateUser.scss';

const CreateUser = ({ usersStore }) => {
  const i18n = useContext(i18nContext);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      phone: '',
      email: '',
      password: '',
      customerType: CUSTOMER_TYPE.CORPORATE,
      companyName: '',
      firstName: '',
      lastName: ''
    },
    initialStatus: { password: true, username: true },
    validationSchema: Yup.object({
      phone: Yup.string().nullable(true).required(i18n.getMessage('validation.emptyField')),
      email: Yup.string().nullable(true).required(i18n.getMessage('validation.emptyField')),
      password: Yup.string()
        .nullable(true)
        .min(7, i18n.getMessage('validation.passwordRequirements'))
        .max(30, i18n.getMessage('validation.fieldSize', { amount: '30' }))
        .required(i18n.getMessage('login.error.emptyFiled', { field: 'password' }))
    }),
    onSubmit: async (values) => {
      //TODO: Submit logic here
    }
  });

  const { values, errors, handleSubmit, handleChange, submitCount } = form;

  return (
    <Container className={'create-user-container'} header={i18n.getMessage('container.createUser')}>
      <form action={'/form'} autoComplete={'off'} id={'send'} onSubmit={handleSubmit} className={'create-user-form'}>
        <div className={'create-user-form-inputs-wrapper'}>
          <Input
            className={'create-user-form-input'}
            type={'text'}
            label={i18n.getMessage('user.create.input.label.phone')}
            name={'phone'}
            autoComplete={'off'}
            value={values.phone}
            onChange={handleChange}
            initialStatus={submitCount}
            error={errors?.phone}
          />
          <Input
            className={'create-user-form-input'}
            type={'email'}
            name={'email'}
            label={i18n.getMessage('user.create.input.label.email')}
            autoComplete={'new-email'}
            value={values.email}
            onChange={handleChange}
            initialStatus={submitCount}
            error={errors?.email}
          />
          <Input
            className={'create-user-form-input'}
            type={'password'}
            name={'password'}
            label={i18n.getMessage('user.create.input.label.password')}
            autoComplete={'new-password'}
            value={values.password}
            onChange={handleChange}
            initialStatus={submitCount}
            error={errors?.password}
          />
          <Input
            className={'create-user-form-input'}
            type={'text'}
            name={'companyName'}
            label={i18n.getMessage('user.create.input.label.companyName')}
            autoComplete={'off'}
            value={values.companyName}
            onChange={handleChange}
            initialStatus={submitCount}
            error={errors?.companyName}
          />
          <Input
            className={'create-user-form-input'}
            type={'text'}
            name={'firstName'}
            label={i18n.getMessage('user.create.input.label.firstName')}
            autoComplete={'off'}
            value={values.firstName}
            onChange={handleChange}
            initialStatus={submitCount}
            error={errors?.firstName}
          />
          <Input
            className={'create-user-form-input'}
            type={'text'}
            name={'lastName'}
            label={i18n.getMessage('user.create.input.label.lastName')}
            autoComplete={'off'}
            value={values.lastName}
            onChange={handleChange}
            initialStatus={submitCount}
            error={errors?.lastName}
          />
          <Button
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            fullWidth={true}
            onClick={() => {}}
            isDisabled={usersStore.isLoading}
          >
            {usersStore.isLoading ? <Loader /> : i18n.getMessage('user.create.button.create')}
          </Button>
        </div>
      </form>
    </Container>
  );
};

CreateUser.propTypes = {
  usersStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  usersStore: stores.usersStore
}))(observer(CreateUser));
