import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { AMOUNT_INPUT_VALUE_TYPE } from 'components/constants';
import i18nContext from 'components/i18n-context';
import { formatDateTime, formatMoney } from 'services/utils';
import AmountInput from 'uikit/AmountInput';
import Button from 'uikit/Button/Button';
import './PopUpAccountDetailsScheme.scss';

const PopUpAccountDetailsScheme = ({
  isLoading,
  accountDetails,
  handleClose,
  handleSetPrimary,
  handleAdjustAccountAmount,
  updateCustomerDetails
}) => {
  const i18n = useContext(i18nContext);
  const [isEditingMode, setIsEditingMode] = useState(false);

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      availableBalance: accountDetails.available.toString(),
      onHoldBalance: accountDetails.on_hold.toString()
    },
    validationSchema: Yup.object().shape({
      availableBalance: Yup.string().required(
        i18n.getMessage('accountsTab.modal.accountDetails.validation.availableBalance.isRequired')
      ),
      onHoldBalance: Yup.string().required(
        i18n.getMessage('accountsTab.modal.accountDetails.validation.onHoldBalance.isRequired')
      )
    }),
    onSubmit: async (values) => {
      await handleAdjustAccountAmount({
        walletNumber: accountDetails?.wallet_number,
        available: Number(values.availableBalance),
        onHold: Number(values.onHoldBalance)
      });
      await updateCustomerDetails();
      handleCloseEditingMode();
      handleClose();
    }
  });

  const { values, handleSubmit, errors, handleChange, resetForm } = form;

  const handleCloseEditingMode = () => {
    resetForm({});
    setIsEditingMode(false);
  };

  return (
    <form className={'scheme-wrapper scheme-wrapper-container'} onSubmit={handleSubmit}>
      <div className={'scheme-wrapper-container-header'}>
        <h3>
          {i18n.getMessage('accountsTab.modal.accountDetails.title', { accountNumber: accountDetails.wallet_number })}
        </h3>
        <CloseIcon className={'scheme-wrapper-container-header-close-icon'} onClick={handleClose} />
      </div>
      <table className={'table-wrapper'}>
        <tbody className={'table-body'}>
          <tr className={'table-row scheme-wrapper-container-marked'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.available')}</td>
            {isEditingMode ? (
              <td className={'table-cell'}>
                <AmountInput
                  className={'account-details-edit-input'}
                  name={'availableBalance'}
                  value={values?.availableBalance}
                  returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                  onChange={handleChange}
                  error={errors?.availableBalance}
                />
              </td>
            ) : (
              <td className={'table-cell'}>
                {formatMoney(accountDetails.available, accountDetails.currency)} {accountDetails.currency}
              </td>
            )}
          </tr>
          <tr className={'table-row scheme-wrapper-container-marked'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.onHold')}</td>
            {isEditingMode ? (
              <td className={'table-cell'}>
                <AmountInput
                  className={'account-details-edit-input'}
                  name={'onHoldBalance'}
                  value={values?.onHoldBalance}
                  returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
                  onChange={handleChange}
                  error={errors?.onHoldBalance}
                />
              </td>
            ) : (
              <td className={'table-cell'}>
                {formatMoney(accountDetails.on_hold, accountDetails.currency)} {accountDetails.currency}
              </td>
            )}
          </tr>
          <tr className={'table-row scheme-wrapper-container-marked'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.total')}</td>
            <td className={'table-cell'}>
              {formatMoney(accountDetails.total, accountDetails.currency)} {accountDetails.currency}
            </td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.iban')}</td>
            <td className={'table-cell'}>{accountDetails.iban || accountDetails.multi_iban}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.country')}</td>
            <td className={'table-cell'}>{accountDetails.bank_country}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.bankName')}</td>
            <td className={'table-cell'}>{accountDetails.bank_name}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.bankAddress')}</td>
            <td className={'table-cell'}>{accountDetails.bank_address}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.bic')}</td>
            <td className={'table-cell'}>{accountDetails.bic}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.paymentMethods')}</td>
            <td className={'table-cell'}>{accountDetails?.payment_methods?.join(', ')}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.createdAt')}</td>
            <td className={'table-cell'}>{formatDateTime(accountDetails.created_at)}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.primary')}</td>
            <td className={'table-cell'}>
              {accountDetails.is_primary_wallet ? i18n.getMessage('yes') : i18n.getMessage('No')}
            </td>
          </tr>
        </tbody>
      </table>
      {isEditingMode ? (
        <div className={'account-details-actions-wrapper'}>
          <Button
            className={'scheme-wrapper-container-button'}
            type={'primary'}
            roleType={'submit'}
            isDisabled={isLoading}
          >
            {i18n.getMessage('accountsTab.modal.accountDetails.saveChanges')}
          </Button>
          <Button
            className={'scheme-wrapper-container-button'}
            type={'outline'}
            onClick={handleCloseEditingMode}
            isDisabled={isLoading}
          >
            {i18n.getMessage('accountsTab.modal.accountDetails.discardChanges')}
          </Button>
        </div>
      ) : (
        <div className={'account-details-actions-wrapper'}>
          <Button
            className={'scheme-wrapper-container-button'}
            type={'primary'}
            onClick={() => handleSetPrimary(accountDetails.wallet_number)}
            isDisabled={isLoading}
          >
            {i18n.getMessage('accountsTab.modal.accountDetails.setAsPrimary')}
          </Button>
          <Button
            className={'scheme-wrapper-container-button'}
            type={'outline'}
            onClick={() => setIsEditingMode(true)}
            isDisabled={isLoading}
          >
            {i18n.getMessage('accountsTab.modal.accountDetails.editBalance')}
          </Button>
        </div>
      )}
    </form>
  );
};

PopUpAccountDetailsScheme.propTypes = {
  isLoading: PropTypes.bool,
  accountDetails: PropTypes.object,
  handleClose: PropTypes.func,
  handleSetPrimary: PropTypes.func,
  handleAdjustAccountAmount: PropTypes.func,
  updateCustomerDetails: PropTypes.func
};

export default PopUpAccountDetailsScheme;
