import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import RichTextEditor from 'react-rte/lib/RichTextEditor';
import { NotesTabHeadRow } from './NotesTabHeadRow';
import NotesTabTableRow from './NotesTabTableRow';
import {
  LATIN_LETTERS_AND_DIGITS_AND_SYMBOLS_REG_EXP,
  MAX_MESSAGE_LENGTH,
  MAX_UPLOADED_FILES_SIZE
} from 'components/constants';
import Loader from 'components/Loader';
import i18nContext from 'components/i18n-context';
import OperatorsAsyncSelect from 'components/OperatorsAsyncSelect';
import { convertHtmlToString, convertMessageToHtmlAndTrimSpaces, convertBytesToMegabytes } from 'services/utils';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import './NotesTab.scss';

const NotesTab = ({
  isLoading,
  notes,
  transactionNumber,
  handleLoadNotes,
  handleAddNote,
  handleDeleteNote,
  handleDownloadDocument
}) => {
  const [noteValue, setNoteValue] = useState(RichTextEditor.createEmptyValue());
  const [assigneeOperator, setAssigneeOperator] = useState(null);
  const [documents, setDocuments] = useState([]);
  const i18n = useContext(i18nContext);

  useEffect(() => {
    handleLoadNotes();
  }, []);

  const addNote = () => {
    if (!new RegExp(LATIN_LETTERS_AND_DIGITS_AND_SYMBOLS_REG_EXP).test(convertHtmlToString(noteValue))) {
      //TODO: Error notification logic
    } else {
      const convertedNote = convertMessageToHtmlAndTrimSpaces(noteValue, assigneeOperator?.id);
      (transactionNumber
        ? handleAddNote(transactionNumber, convertedNote, assigneeOperator?.id, documents)
        : handleAddNote(convertedNote, assigneeOperator?.id, documents)
      ).then(() => {
        setNoteValue(RichTextEditor.createEmptyValue());
        setAssigneeOperator(null);
        setDocuments([]);
      });
    }
  };

  const handleAttachDocument = ({ target: { files } }) => {
    if (Object.keys(files).length) {
      for (let i = 0; i < files.length; i++) {
        // fix undefined id
        files[i]['id'] = documents.length + i;
      }

      setDocuments([...documents, ...files]);
    }
  };

  const handleRemoveDocument = (documentId) => {
    setDocuments(documents.filter((document) => document.id !== documentId));
  };

  if (!notes) {
    return <Loader className={'application-loader customer-notes-tab-loader'} />;
  }

  return (
    <div className={'customer-notes-container'}>
      <Table responsive>
        <thead>
          <NotesTabHeadRow />
        </thead>
        <tbody>
          {notes.map((note) => (
            <NotesTabTableRow
              key={note.id}
              isLoading={false}
              noteData={note}
              handleRemoveNote={handleDeleteNote}
              handleDownloadDocument={handleDownloadDocument}
            />
          ))}
        </tbody>
      </Table>

      <OperatorsAsyncSelect
        className={'customer-notes-select'}
        handleValueChanged={setAssigneeOperator}
        value={assigneeOperator}
      />

      <Input
        className={'customer-notes-container-input'}
        type={'text-editor'}
        name={'note'}
        isDisabled={false}
        value={noteValue}
        onChange={setNoteValue}
        max={MAX_MESSAGE_LENGTH}
        rows={5}
        /* eslint-disable-next-line max-len */
        subText={i18n.getMessage('customerNotes.input.note.symbolsLeft', {
          symbolsLeft: MAX_MESSAGE_LENGTH - convertHtmlToString(noteValue).length
        })}
      />

      <AttachDoc
        label={i18n.getMessage('customerNotes.attachments')}
        className={'notes-attachment'}
        type={'file'}
        size={MAX_UPLOADED_FILES_SIZE}
        files={documents}
        onChange={handleAttachDocument}
        onRemoveFiles={handleRemoveDocument}
        isDisabled={isLoading}
        hintText={i18n.getMessage('attachDoc.hint.text', {
          maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
        })}
      />

      <div className={'customer-notes-container-add-note'}>
        <Button
          className={'customer-notes-container-add=note-button'}
          type={'primary'}
          size={'small'}
          onClick={addNote}
          isDisabled={!noteValue || isLoading}
        >
          {isLoading ? <Loader /> : i18n.getMessage('customerNotes.button.addNote')}
        </Button>
      </div>
    </div>
  );
};

NotesTab.propTypes = {
  isLoading: PropTypes.bool,
  notes: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  transactionNumber: PropTypes.string,
  handleLoadNotes: PropTypes.func,
  handleAddNote: PropTypes.func,
  handleDeleteNote: PropTypes.func,
  handleDownloadDocument: PropTypes.func
};

export default NotesTab;
