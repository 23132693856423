import PropTypes from 'prop-types';
import { IconButton } from 'uikit/IconButton/IconButton';
import './CustomTabs.scss';

const CustomTabs = ({ isDisabled, direction, tabList, activeTab, setActiveTab }) => {
  return (
    <div className={`custom-tabs-wrapper ${direction}`}>
      {tabList.length > 0 &&
        tabList.map((tab) => (
          <IconButton
            key={tab.name}
            className={`custom-tab-button ${activeTab === tab.name ? 'custom-tab-button-active' : ''}`}
            active={activeTab === tab.name}
            onClick={() => setActiveTab(tab.name)}
            Icon={tab.icon ? tab.icon : undefined}
            text={tab.title}
            isDisabled={tab.name === 'limits' || tab.name === 'amlForm'}
          />
        ))}
    </div>
  );
};

CustomTabs.propTypes = {
  isDisabled: PropTypes.string,
  direction: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.elementType
    })
  ).isRequired
};

export default CustomTabs;
