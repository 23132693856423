import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { convertDate } from 'services/utils';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Button from 'uikit/Button/Button';
import './CustomerStatementTab.scss';

const CustomerStatementTab = ({ loadCustomerStatementPdfReport, loadCustomerStatementCsvReport }) => {
  const i18n = useContext(i18nContext);
  const currentDate = new Date();
  const [fromDate, setFromDate] = useState({
    name: 'from',
    value: null
  });
  const [toDate, setToDate] = useState({
    name: 'to',
    value: null
  });

  const handleFromDateChange = (name, value) => {
    setFromDate({ name, value });
  };

  const handleToDateChange = (name, value) => {
    setToDate({ name, value });
  };

  const handleDownloadPdf = () => {
    loadCustomerStatementPdfReport(convertDate(fromDate.value), convertDate(toDate.value));
  };

  const handleDownloadCsv = () => {
    loadCustomerStatementCsvReport(convertDate(fromDate.value), convertDate(toDate.value));
  };

  return (
    <div className={'statement-container'}>
      <div>
        <span>{i18n.getMessage('customerStatementTab.input.from')}</span>
        <CustomDatePicker
          onChange={handleFromDateChange}
          name={'from'}
          value={fromDate.value}
          maxDate={toDate.value || currentDate}
        />
      </div>
      <div>
        <span>{i18n.getMessage('customerStatementTab.input.to')}</span>
        <CustomDatePicker
          onChange={handleToDateChange}
          name={'to'}
          value={toDate.value}
          maxDate={currentDate}
          minDate={fromDate.value}
        />
      </div>
      <Button
        className={'statement-container-button'}
        onClick={handleDownloadPdf}
        isDisabled={!fromDate.value || !toDate.value}
      >
        {i18n.getMessage('customerStatementTab.button.pdf')}
      </Button>
      <Button
        className={'statement-container-button'}
        onClick={handleDownloadCsv}
        isDisabled={!fromDate.value || !toDate.value}
      >
        {i18n.getMessage('customerStatementTab.button.csv')}
      </Button>
    </div>
  );
};

CustomerStatementTab.propTypes = {
  loadCustomerStatementPdfReport: PropTypes.func.isRequired,
  loadCustomerStatementCsvReport: PropTypes.func.isRequired
};

export default CustomerStatementTab;
