import { convertDate, formatDateTime } from 'services/utils';

const commonInformation = (data) => ({
  accountRegistrationTime: `${formatDateTime(data.user.registration_date)} IP:${data.user.registration_ip}` || '',
  accountNumber: data.account_number || '',
  limit: data.limit_group || '',
  tariff: data.tariff_group || '',
  documents: data.documents_status || '',
  hashtags: data.hashtags ? data.hashtags.join('\n') : '',
  companyName: data.company_name || '',
  registrationNumber: data.registration_number_official || '',
  jurisdictionCode: data.jurisdiction_code || '',
  entityLegalType: data.entity_type || '',
  registrationDate: data.registration_date_official || '',
  webSite: data.web_site || 'no',
  businessNature: data.business_nature || ''
});

const address = (data) => ({
  address1: data.address_1 || '',
  address2: data.address_2 || '',
  city: data.city || '',
  country: data.country || '',
  zipCode: data.zip_code || ''
});

const userDetails = (data) => ({
  address: address(data.address || {}),
  birthday: data.birthday || '',
  passportNumber: data.passport_number || '',
  passportCountry: data.passport_issue_country || '',
  passportDate: data.passport_issue_date || '',
  passportExpiryDate: data.passport_expiry_date || ''
});

const userData = (data) => ({
  accountNumber: data.account_number || '',
  name: data.name || '',
  surname: data.surname || '',
  details: userDetails(data.details || {}),
  passport_check: data.passport_check || 'PASS',
  sanction_list: data.sanction_list || 'PASS',
  pep_list: data.pep_list || 'PASS',
  black_list: data.black_list || 'PASS'
});

const commonInformationToServer = (data) => ({
  web_site: data.webSite || '',
  registration_number_official: data.registrationNumber || '',
  jurisdiction_code: data.jurisdictionCode || '',
  registration_date_official: convertDate(data.registrationDate) || null,
  hashtags: data.hashtags ? data.hashtags.split('\n') : [],
  entity_type: data.entityLegalType || '',
  company_name: data.companyName || '',
  business_nature: data.businessNature || '',
  industry_type: data.industryType || null,
  account_opening_purpose: data.accountOpeningPurpose || null
});

const addressToServer = (data) => ({
  address_1: data.address1 || '',
  address_2: data.address2 || '',
  city: data.city || '',
  country: data.country || '',
  zip_code: data.zipCode || ''
});

const partnerData = (data) =>
  data !== null
    ? {
        country: data.country,
        description: data.description,
        name: data.name
      }
    : null;

const turnover = (data) => ({
  currency: data.currency,
  type: data.type,
  value: data.value
});

const fromMemberDto = (dto) => ({
  accountNumber: dto?.account_number,
  name: dto?.name || '',
  surname: dto?.surname || '',
  birthday: dto?.birthday ? new Date(dto.birthday) : '',
  passportNumber: dto?.passport_number || '',
  passportIssueCountry: dto?.passport_issue_country || '',
  passportIssueDate: dto?.passport_issue_date ? new Date(dto.passport_issue_date) : '',
  passportExpiryDate: dto?.passport_expiry_date ? new Date(dto.passport_expiry_date) : '',
  address: fromAddressDto(dto?.address),
  passportCheck: dto?.passport_check || '',
  sanctionList: dto?.sanction_list || '',
  pepList: dto?.pep_list || '',
  blackList: dto?.black_list || ''
});

const toMemberDto = (member) => ({
  account_number: member.accountNumber,
  name: member.name,
  surname: member.surname,
  birthday: member.birthday,
  passport_number: member.passportNumber,
  passport_issue_country: member.passportIssueCountry,
  passport_issue_date: member.passportIssueDate,
  passport_expiry_date: member.passportExpiryDate,
  address: toAddressDto(member.address),
  passport_check: member.passportCheck,
  sanction_list: member.sanctionList,
  pep_list: member.pepList,
  black_list: member.blackList
});

const fromAuthorizedPersonDto = (dto) => ({
  ...fromMemberDto(dto),
  passportCheck: dto?.passport_check || 'PASS',
  sanctionList: dto?.sanction_list || 'PASS',
  pepList: dto?.pep_list || 'PASS',
  blackList: dto?.black_list || 'PASS',
  email: dto?.email || '',
  beneficiary: typeof dto?.beneficiary === 'boolean' ? dto.beneficiary : true,
  director: typeof dto?.director === 'boolean' ? dto.director : true,
  residenceCountry: dto?.residence_country || '',
  memberOfTheBoard: typeof dto?.member_of_the_board === 'boolean' ? dto.member_of_the_board : true,
  representative: typeof dto?.representative === 'boolean' ? dto.representative : true,
  shareholder: typeof dto?.shareholder === 'boolean' ? dto.shareholder : true,
  percentOfEquity: dto?.percent_of_equity || '',
  position: dto?.position || '',
  title: dto?.title || null,
  accountOpeningPurpose: dto?.account_opening_purpose || ''
});

const toAuthorizedPersonDto = (authorizedPerson) => ({
  ...toMemberDto(authorizedPerson),
  email: authorizedPerson.email,
  beneficiary: authorizedPerson.beneficiary,
  director: authorizedPerson.director,
  residence_country: authorizedPerson.residenceCountry,
  member_of_the_board: authorizedPerson.memberOfTheBoard,
  representative: authorizedPerson.representative,
  shareholder: authorizedPerson.shareholder,
  percent_of_equity: authorizedPerson.percentOfEquity,
  position: authorizedPerson.position,
  title: authorizedPerson.title,
  account_opening_purpose: authorizedPerson.accountOpeningPurpose
});

const fromAddressDto = (dto) => ({
  address1: dto?.address_1 || '',
  address2: dto?.address_2 || '',
  city: dto?.city || '',
  country: dto?.country || '',
  zipCode: dto?.zip_code || ''
});

const toAddressDto = (address) => ({
  address_1: address.address1,
  address_2: address.address2,
  city: address.city,
  country: address.country,
  zip_code: address.zipCode
});

const fromBeneficiaryDto = (dto) => ({
  ...fromMemberDto(dto),
  percentOfEquity: dto?.percent_of_equity || '',
  director: typeof dto?.director === 'boolean' ? dto.director : ''
});

const toBeneficiaryDto = (beneficiary) => ({
  ...toMemberDto(beneficiary),
  percent_of_equity: beneficiary.percentOfEquity,
  director: beneficiary.director
});

const fromDirectorDto = (dto) => ({
  ...fromMemberDto(dto)
});

const toDirectorDto = (dto) => ({
  ...toMemberDto(dto)
});

export default {
  commonInformation,
  address,
  userData,
  partnerData,
  turnover,
  commonInformationToServer,
  addressToServer,
  fromAuthorizedPersonDto,
  toAuthorizedPersonDto,
  fromBeneficiaryDto,
  toBeneficiaryDto,
  fromDirectorDto,
  toDirectorDto
};
